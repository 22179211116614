html{
  min-height:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'Rubik',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/img/bg.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #2b302f;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form{
  padding: 20px;
}

form button,form h5{
  margin: 20px 0;
}

input[type=text]:not(.browser-defailt):focus:not([readonly]),
input[type=email]:not(.browser-defailt):focus:not([readonly]),
input[type=password]:not(.browser-defailt):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #ec407a;
  box-shadow: none;
}

